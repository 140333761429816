<template>
  <form class="modal-inner-container" @submit.prevent="resetPassword">
    <div class="w-full">
      <div class="text-n-4xl font-bold">
        {{$t('password.choose_new_password')}}
      </div>

      <div class="self-start text-n-md font-semibold mt-n-5xl">
        {{$t('password.enter_new_password')}}
      </div>

      <div class="relative w-full">
        <input
          ref="newPasswordRef"
          v-model="newPassword"
          @focus="$refs.validatePassword.selectField('password')"
          data-testid="input-new-pw"
          class="v2 mt-n-2xl pl-n-4xl pr-n-8xl py-n-3xl"
          :type="showNewPassword ? 'text' : 'password'"
          data-lpignore="true"
        />
        <div @click="showNewPassword=!showNewPassword" class="absolute top-0 right-0 mt-n-5xl mr-n-3xl text-n-sm font-medium cursor-pointer">
          {{$t(showNewPassword ? 'password.hide' : 'password.show')}}
        </div>
      </div>

      <div class="self-start text-n-md font-semibold mt-n-5xl">
        {{$t('password.enter_new_password_again')}}
      </div>

      <div class="relative w-full">
        <input
          v-model="confirmPassword"
          @focus="$refs.validatePassword.selectField('confirmPassword')"
          data-testid="input-confirm-pw"
          class="v2 mt-n-2xl pl-n-4xl pr-n-8xl py-n-3xl"
          :type="showConfirmPassword ? 'text' : 'password'"
          data-lpignore="true"
        />
        <div @click="showConfirmPassword=!showConfirmPassword" class="absolute top-0 right-0 mt-n-5xl mr-n-3xl text-n-sm font-medium cursor-pointer">
          {{$t(showConfirmPassword ? 'password.hide' : 'password.show')}}
        </div>
      </div>

      <ValidatePassword ref="validatePassword"
        @setIsPasswordValid="setIsPasswordValid"
        :confirmPassword="confirmPassword"
        :password="newPassword"
        class="mt-n-3xl"
      />
    </div>

    <!-- Buttons -->
    <div class="flex w-full justify-between mt-n-5xl">
      <ButtonV2
        :label="$t('password.save')"
        testId="btn-save"
        :inactive="isLoading || !isFormValid"
        submit
        wide
      />
    </div>
  </form>
</template>

<script>
import PASSWORD_RESET from '@/graphql/mutations/PasswordReset.gql';
import ButtonV2 from '@/stories/misc/ButtonV2';
import ValidatePassword from '@/components/misc/ValidatePassword';

export default {
  name: 'ResetPassword',
  components: {
    ButtonV2,
    ValidatePassword,
  },
  data: function () {
    return {
      confirmPassword: '',
      isLoading: false,
      isPasswordValid: false,
      isConfirmPasswordValid: false,
      newPassword: '',
      showConfirmPassword: false,
      showNewPassword: false,
    };
  },

  async mounted() {
    await this.$store.dispatch('events/track', {
      event: 'RESET_PASSWORD_VIEWED',
    });
    this.$nextTick(() => {
      this.$refs.newPasswordRef.focus();
    });
  },

  computed: {
    isFormValid() {
      return  this.newPassword && this.confirmPassword && this.isPasswordValid && this.isConfirmPasswordValid;
    },
  },

  methods: {
    async resetPassword() {
      if (this.newPassword !== this.confirmPassword) {
        return this.showError(new Error('confirm_pw_not_equals_new_pw'));
      }

      this.isLoading = true;
      try {
        await this.apolloApiCall({
          mutation: PASSWORD_RESET,
          variables: {
            secret: this.$route.query.token,
            password: this.newPassword,
          }
        });

        this.showSuccess(this.$t('success.password_changed'));
        this.hideModal();
      } catch (err) {
        await this.showError(err);
      }
      this.isLoading = false;
    },

    setIsPasswordValid({ isPasswordValid, isConfirmPasswordValid }) {
      this.isPasswordValid = isPasswordValid;
      this.isConfirmPasswordValid = isConfirmPasswordValid;
    },
  },
};
</script>

<style scoped>
</style>
